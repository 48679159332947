<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>内容管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:topath}">{{toptitle}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-form style="width: 80%;text-align: left;" label-width="80px">
            <el-form-item label="封面图片">
                <upload :src.sync="fmpic"></upload>
            </el-form-item>
            <el-form-item label="排序">
                <el-input v-model="sortnum" placeholder="数字越大排名越靠前" autocomplete="off" style="width: 15%;float: left;">
                </el-input>
            </el-form-item>
            <el-form-item label="文章标题">
                <el-input v-model="articleT" placeholder="请输入标题" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="文章详情">
                <el-input type="textarea" v-model="content" :rows="8"></el-input>
            </el-form-item>
            <div style="margin-top: 80px;text-align: center;">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="sub">确 定</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
    import upload from '../components/upload'
    export default {
        components: {
            upload
        },
        data() {
            return {
                type: '',
                id: '',
                articleT: '',
                content: '',
                title: '添加文章',
                fmpic: '',
                date: '',
                radio: '0',
                sortnum: '',
                toptitle: '',
                topath: ''
            }
        },
        mounted() {
            this.type = this.$route.query.type;
            this.id = this.$route.query.id;
            this.toptitle = '用户上传文章';
            this.topath = 'userArticleList';
            if (this.id) {
                //读取详情接口
                this.axios.get("/gu/get_gu_article_by_id?id=" + this.id)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.articleT = res.data.result.title;
                            this.content = res.data.result.content;
                            this.fmpic = res.data.result.article_pic;
                            this.sortnum = res.data.result.sort;
                        } else {
                            console.log(res);
                        }
                    });
            }
            if (this.type != 'new') {
                this.title = '编辑文章'
            }
        },
        methods: {
            cancel() {
                this.$router.push('/userArticleList');
            },
            sub() {
                let str, url;
                let form = {
                    title: this.articleT,
                    content: this.content,
                    article_pic: this.fmpic,
                    sort:this.sortnum,
                    cate:'9',
                    doc_id:'',
                }
                if (this.type != 'new') {
                    form.id = this.id;
                    str = '修改成功';
                    url = "/gu/update_gu_article";
                } else {
                    str = "添加成功";
                    url = "/gu/insert_gu_article";
                }
                console.log(form)
                this.axios.post(url, this.qs.stringify(form))
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$message({
                                message: str,
                                type: 'success'
                            });
                            this.$router.push('/userArticleList');
                        } else {
                            this.$message(res.data.msg);
                        }
                    });
            },
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }
</style>